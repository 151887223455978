import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ShowArticuloGridComponent } from '../components/show-articulo-grid/show-articulo-grid.component'
import { MiniaturaArticuloVentaComponent } from '../components/miniatura-articulo-venta/miniatura-articulo-venta.component'
import { DetalleArticuloVentaComponent } from '../components/detalle-articulo-venta/detalle-articulo-venta.component'
import { LineaArticuloVentaComponent } from '../components/linea-articulo-venta/linea-articulo-venta.component'
import { MiniaturaArticuloInfoComponent } from '../components/miniatura-articulo-info/miniatura-articulo-info.component'
import { FeaturedProductsComponent } from '../components/featured-products/featured-products.component';

import { IonicModule } from '@ionic/angular';

import { Ng2SearchPipeModule } from 'ng2-search-filter';


import { FiltroArticuloPipe } from '../filtro-articulo.pipe';


@NgModule({
    declarations: 
        [DetalleArticuloVentaComponent,MiniaturaArticuloInfoComponent,MiniaturaArticuloVentaComponent,FiltroArticuloPipe,ShowArticuloGridComponent,FeaturedProductsComponent,LineaArticuloVentaComponent],
    imports: 
        [CommonModule, IonicModule,Ng2SearchPipeModule,
            CommonModule],
    exports:
        [DetalleArticuloVentaComponent,MiniaturaArticuloInfoComponent,MiniaturaArticuloVentaComponent,FiltroArticuloPipe,ShowArticuloGridComponent,FeaturedProductsComponent,LineaArticuloVentaComponent]
})
export class SharedModule {}
