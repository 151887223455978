import { Component, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { EventEmitter, Output } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Product } from "../../models/product.model";
import { ProductsService } from "../../services/products.service";
import { ViewerModalComponent } from 'ngx-ionic-image-viewer';
import { cantidadesService } from "../../services/cantidades.service";
import {
    PopoverController,
    ModalController,
    LoadingController,
    IonInfiniteScroll,
    ToastController,
    NavController
} from "@ionic/angular";
import { GlobalService } from "../../services/global.service";
import { CartService } from "../../services/cart.service";
import { ApiService } from "../../api.service";
import { Storage } from "@ionic/storage";
import { Router, ActivatedRoute } from "@angular/router";
import { Carro } from "../../models/carro.model";
import { CategoryService } from "../../services/category.service";

import { ProductDetailsComponent } from "../../pages/product-details/product-details.component"


@Component({
    selector: 'app-detalle-articulo-venta',
    templateUrl: './detalle-articulo-venta.component.html',
    styleUrls: ['./detalle-articulo-venta.component.scss'],
})
export class DetalleArticuloVentaComponent implements OnInit {

    @ViewChild(IonInfiniteScroll, { static: false })
    infiniteScroll: IonInfiniteScroll;

    @Output() favEvent: EventEmitter<any> = new EventEmitter();

    @Input() products: Product[];
    @Input() grid: number;    
    @Input() item: Product;
    @Input() historico : boolean = false;

    private itemsFound = 1;

    private cartProducts: Carro;

    private incremento = 10;

    constructor(
        private navController: NavController,
        private activeRoute: ActivatedRoute,
        private cartService: CartService,
        private route: ActivatedRoute,
        private router: Router,
        public popoverController: PopoverController,
        private productsService: ProductsService,
        public storage: Storage,
        public ctdadService: cantidadesService,
        public modalController: ModalController,
        public global: GlobalService,
        public loadingController: LoadingController,
        public apiService: ApiService,
        public toastController: ToastController,
        private categoryService: CategoryService,
        private sanitizer: DomSanitizer
    ) {
    }

    getSafeHtml(html: string): SafeHtml {
      // Marcar el HTML como seguro para que respete estilos embebidos y otras propiedades.
      return this.sanitizer.bypassSecurityTrustHtml(html);
    }
    
    
    ngOnInit() {
    }

    async FavAdd(_product, e) {
        e.stopPropagation();

        for (let i = 0; i < this.products.length; i++) {
            if (this.products[i].codigo === _product) {
                await this.apiService.FavAdd(
                    this.products[i].codigo
                );
                this.products[i].isWishlist = true;
                this.global.cambio = true;
                this.favEvent.emit();
            }
        }
    }

    async FavDel(_product, e) {
        e.stopPropagation();

        for (let i = 0; i < this.products.length; i++) {
            if (this.products[i].codigo === _product) {
                await this.apiService.FavDel(
                    this.products[i].codigo
                );
                this.products[i].isWishlist = false;
                this.global.cambio = true;
                this.favEvent.emit();
            }
        }
    }

    async addtocart(_product, i) {
        console.log("detalle-venta");
        _product = await this.apiService.dameUnidades(_product);
        await this.apiService.cartAdd(_product,false);
        this.global.searchTerm = "";
        this.getCartItems(_product.nombre);
    }


    async getCartItems(_productName) {
        
        this.cartService.cart().then((products) => {
            this.cartProducts = products;
            const object = this.cartProducts.articulos;
            console.log("Articulos carro: " + object);
            this.global.artcart = Object.keys(object).length;
        });
        const toast = await this.toastController.create({
            message: _productName + " añadido a tu carrito.",
            duration: 2000,
        });
        toast.present();
    }

    
    async compartir(_productName, e) {
        console.log("compartir");
        e.stopPropagation();

        this.global.itemChat = _productName;
        this.router.navigate(['/tabs/tab2']);
    }

    async focusUnidades(_unidades) 
    {
        try {
            _unidades.unid1 = ( _unidades.unid1 == "0" ? "" : _unidades.unid1 );
            _unidades.unid2 = ( _unidades.unid2 == "0" ? "" : _unidades.unid2 );
        } catch (error) {
            console.log("unidades:",error);
        }
    }

    async openViewer(_producto) {
        console.log("inside");
        console.log(_producto.imagen);
        console.log(_producto.nombre);
        
        const modal = await this.modalController.create({
          component: ViewerModalComponent,
          componentProps: {
            src: _producto.imagen, // required
            //title: 'Silhoutte (Programmatic)', // optional
            text: _producto.nombre, // optional
          },
          cssClass: 'ion-img-viewer', // required
          keyboardClose: true,
          showBackdrop: true,
        });
    
        return await modal.present();
      }
}
